import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { PolarArea } from 'react-chartjs-2';

interface InventoryPolarProps {
  polarChartData: any;
  chartOptions: any;
}

const InventoryPolar: React.FC<InventoryPolarProps> = ({ polarChartData, chartOptions }) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ p: 1.25 }}>
        <Typography variant="subtitle1" gutterBottom>
          INVENTORY
        </Typography>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '300px',
          }}
        >
          <PolarArea data={polarChartData} options={chartOptions} redraw />
        </div>
      </CardContent>
    </Card>
  );
};

export default InventoryPolar;

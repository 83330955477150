import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';

import CustomSelect from '../../../../partial/CustomSelect';

// Define interface for channel object
interface Channel {
  label: string;
  value: string;
}

interface DashboardHeaderProps {
  period: string;
  view: string;
  channels: Channel[];
  type: string;
  datesModal: boolean;
  start: Date | null;
  end: Date | null;
  onChange: (state: string) => (e: any) => void;
  toggleDatesModal: () => void;
  searchByCustomDate: () => void;
  handleDateChange: (state: string) => (date: Date | null) => void;
  selectChannels: (value: Channel[]) => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  period,
  view,
  channels,
  type,
  datesModal,
  start,
  end,
  onChange,
  toggleDatesModal,
  searchByCustomDate,
  handleDateChange,
  selectChannels,
}) => {
  // Format date for display in input fields
  const formatDate = (date: Date | null) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Handle date input changes manually
  const handleManualDateChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    if (dateValue) {
      const newDate = new Date(dateValue);
      handleDateChange(field)(newDate);
    } else {
      handleDateChange(field)(null);
    }
  };

  return (
    <Card sx={{ mb: 1, mt: 0 }}>
      <CardContent sx={{ p: 1.25, '&:last-child': { pb: 1.25 } }}>
        <Grid container spacing={1} alignItems="center">
          {/* Time Period Select */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="period-label">Time Range</InputLabel>
              <Select
                labelId="period-label"
                id="period-select"
                value={period}
                label="Time Range"
                onChange={onChange('period')}
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="yesterday">Yesterday</MenuItem>
                <MenuItem value="last-hour">Last Hour</MenuItem>
                <MenuItem value="last-24-hours">Last 24 Hours</MenuItem>
                <MenuItem value="last-7-days">Last 7 Days</MenuItem>
                <MenuItem value="last-30-days">Last 30 Days</MenuItem>
                <MenuItem value="previous-week">Previous Week</MenuItem>
                <MenuItem value="3-months">Last 3 Months</MenuItem>
                <MenuItem value="1-year">Last Year</MenuItem>
                <MenuItem value="custom">Custom Range</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Channel Select using CustomSelect */}
          <Grid item xs={12} sm={6} md={3}>
            <CustomSelect
              className="full-width"
              label="channels"
              placeholder="All Channels"
              value={channels}
              onChange={onChange('channels')}
              schema={{ label: 'name', value: '_id' }}
              query={{ type: { $ne: 'default' } }}
              service="v1/objects/sales-channels"
              closeMenuOnSelect
              multi
            />
          </Grid>

          {/* Metric Type Select */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="type-label">Metric</InputLabel>
              <Select labelId="type-label" id="type-select" value={type} label="Metric" onChange={onChange('type')}>
                <MenuItem value="revenue">Revenue</MenuItem>
                <MenuItem value="orders">Orders</MenuItem>
                <MenuItem value="users">Customers</MenuItem>
                <MenuItem value="conversions">Conversions</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Map View Select */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="view-label">Map View</InputLabel>
              <Select labelId="view-label" id="view-select" value={view} label="Map View" onChange={onChange('view')}>
                <MenuItem value="world">World</MenuItem>
                <MenuItem value="usa">USA</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>

      {/* Custom Date Range Dialog */}
      <Dialog open={datesModal} onClose={toggleDatesModal} aria-labelledby="date-range-dialog">
        <DialogTitle id="date-range-dialog">Select Custom Date Range</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 1, minWidth: 300 }}>
            <TextField
              label="Start Date"
              type="date"
              value={formatDate(start)}
              onChange={handleManualDateChange('start')}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
            />
            <TextField
              label="End Date"
              type="date"
              value={formatDate(end)}
              onChange={handleManualDateChange('end')}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDatesModal}>Cancel</Button>
          <Button onClick={searchByCustomDate} variant="contained" color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DashboardHeader;

import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';

// Register Chart.js components explicitly
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

// Material UI color palette for charts (solid colors with white borders)
const materialChartColors = {
  backgroundColor: [
    'rgb(33, 150, 243)', // Blue
    'rgb(0, 150, 136)', // Teal
    'rgb(156, 39, 176)', // Purple
    'rgb(255, 152, 0)', // Orange
    'rgb(244, 67, 54)', // Red
    'rgb(255, 193, 7)', // Amber
    'rgb(76, 175, 80)', // Green
    'rgb(63, 81, 181)', // Indigo
    'rgb(233, 30, 99)', // Pink
    'rgb(0, 188, 212)', // Cyan
  ],
  borderColor: Array(10).fill('#ffffff'), // White borders for all items
};

interface TopSellingItemsProps {
  barChartData: any;
  chartOptions: any;
}

const TopSellingItems: React.FC<TopSellingItemsProps> = ({ barChartData, chartOptions }) => {
  const [chartType, setChartType] = useState<'bar' | 'doughnut'>('bar');
  const [processedData, setProcessedData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noDataMessage, setNoDataMessage] = useState<string | null>(null);

  // Process the incoming data
  useEffect(() => {
    setIsLoading(true);
    setNoDataMessage(null);

    try {
      // Check if we have valid data
      if (!barChartData || !barChartData.labels || !barChartData.datasets || barChartData.labels.length === 0) {
        setNoDataMessage('No sales data available for the selected period');
        setIsLoading(false);
        return;
      }

      // Create a deep copy to avoid modifying the original data
      const processedChartData = JSON.parse(JSON.stringify(barChartData));
      const dataset = processedChartData.datasets[0];

      // Apply Material UI colors
      const bgColors: string[] = [];
      const bdColors: string[] = [];

      for (let i = 0; i < processedChartData.labels.length; i++) {
        const colorIndex = i % materialChartColors.backgroundColor.length;
        bgColors.push(materialChartColors.backgroundColor[colorIndex]);
        bdColors.push(materialChartColors.borderColor[colorIndex]);
      }

      dataset.backgroundColor = bgColors;
      dataset.borderColor = bdColors;

      // Set borderWidth consistently
      dataset.borderWidth = 3; // Slightly thicker border to make white more visible

      // Remove any parsing configuration coming from the server to avoid conflicts
      if (dataset.parsing) {
        delete dataset.parsing; // We'll handle parsing at the chart options level
      }

      setProcessedData(processedChartData);
    } catch (err) {
      setNoDataMessage('Error processing chart data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [barChartData]);

  // Bar chart options
  const barOptions = {
    indexAxis: 'y' as const, // Horizontal bar chart
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        titleFont: {
          weight: 'bold' as const,
        },
        padding: 12,
        callbacks: {
          label: function (context) {
            // Handle both object data format and simple array format
            let value = 0;
            if (typeof context.raw === 'object' && context.raw !== null) {
              value = context.raw.y || 0;
            } else {
              value = context.raw || 0;
            }
            return `Quantity: ${Math.round(value).toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.05)',
        },
        ticks: {
          font: {
            size: 11,
          },
          padding: 5,
          stepSize: 1, // Force integer steps
          callback: function (value) {
            return Math.round(value).toLocaleString(); // Always show as integer
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
          },
          // Labels are already truncated in the API response
          callback: function (value) {
            return this.getLabelForValue(value);
          },
        },
      },
    },
    // Use correct parsing configuration for the data format
    parsing: {
      xAxisKey: 'y',
      yAxisKey: 'x',
    },
  };

  // Doughnut chart options
  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '60%',
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          font: {
            size: 11,
          },
          padding: 15,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        padding: 12,
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            // Handle both object data format and simple array format
            let value = 0;
            if (typeof context.raw === 'object' && context.raw !== null) {
              value = context.raw.y || 0;
            } else {
              value = context.raw || 0;
            }
            const percentage = context.dataset.data.reduce
              ? Math.round(
                  (value / context.dataset.data.reduce((a, b) => a + (typeof b === 'object' ? b.y : b), 0)) * 100
                )
              : 0;

            return `${label}: ${Math.round(value).toLocaleString()} (${percentage}%)`;
          },
        },
      },
    },
    // For doughnut charts with object data format
    parsing: {
      key: 'y',
    },
  };

  const toggleChartType = () => {
    setChartType(prevType => (prevType === 'bar' ? 'doughnut' : 'bar'));
  };

  // Render the no data message with styling
  const renderNoDataMessage = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        p: 3,
      }}
    >
      <Typography variant="h6" color="text.secondary" gutterBottom>
        No Data Available
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {noDataMessage || 'No sales data available for the selected criteria.'}
      </Typography>
    </Box>
  );

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      }}
    >
      <CardContent
        sx={{
          p: 1.25,
          '&:last-child': { pb: 1.25 },
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            TOP 25 SELLING ITEMS
          </Typography>
          {processedData && !noDataMessage && (
            <Button size="small" color="primary" onClick={toggleChartType}>
              Switch to {chartType === 'bar' ? 'Doughnut' : 'Bar'} Chart
            </Button>
          )}
        </Box>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            flexGrow: 1,
            minHeight: '420px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(0, 0, 0, 0.08)',
            borderRadius: 1,
            p: 2,
            backgroundColor: '#fafafa',
          }}
        >
          {isLoading ? (
            <Typography>Loading chart data...</Typography>
          ) : noDataMessage ? (
            renderNoDataMessage()
          ) : processedData ? (
            chartType === 'bar' ? (
              <Bar data={processedData} options={barOptions} />
            ) : (
              <Doughnut data={processedData} options={doughnutOptions} />
            )
          ) : (
            renderNoDataMessage()
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TopSellingItems;

import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

interface StatCardProps {
  title: string;
  currentValue: number;
  previousValue: number;
  comparisonLabel: string;
  isCurrency?: boolean;
  currency?: string;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  currentValue,
  previousValue,
  comparisonLabel,
  isCurrency = false,
  currency = 'USD',
}) => {
  // Format number as currency if needed
  const formatValue = (value: number) => {
    if (isCurrency) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    }
    return new Intl.NumberFormat('en-US').format(value);
  };

  // Calculate percentage change
  const calculateChange = () => {
    if (previousValue === 0) return currentValue > 0 ? 100 : 0;
    return ((currentValue - previousValue) / previousValue) * 100;
  };

  const percentChange = calculateChange();

  // Determine trend icon and color
  const getTrendDetails = () => {
    if (percentChange > 0) {
      return {
        icon: <TrendingUpIcon fontSize="small" />,
        color: 'success.main',
      };
    } else if (percentChange < 0) {
      return {
        icon: <TrendingDownIcon fontSize="small" />,
        color: 'error.main',
      };
    }
    return {
      icon: <TrendingFlatIcon fontSize="small" />,
      color: 'text.secondary',
    };
  };

  const { icon, color } = getTrendDetails();

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ p: 1.25 }}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div" sx={{ mt: 1, fontWeight: 'bold' }}>
          {formatValue(currentValue)}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
          Previous: {formatValue(previousValue)}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Box sx={{ color, display: 'flex', alignItems: 'center', mr: 1 }}>
            {icon}
            <Typography variant="body2" component="span" sx={{ ml: 0.5, color }}>
              {Math.abs(percentChange).toFixed(1)}%
            </Typography>
          </Box>
          <Typography variant="caption" color="textSecondary">
            vs {comparisonLabel}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatCard;

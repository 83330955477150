import { Box, Grid } from '@mui/material';
import React, { Component } from 'react';

import { DashboardHeader, MapVisualization, StatCard } from './components';
import { TopSellingItems } from './components/charts';
import RegionStatsTable from './components/RegionStatsTable';
import ChartRegistration from './utils/ChartRegistration';
import connect from '../../../../lib/connect';

interface ReportResponse {
  isTotals?: boolean;
  state: string;
  country: string;
  currentPeriod: {
    revenue: number;
    users: number;
    orders: number;
    conversions: number;
  };
  previewsPeriod: {
    revenue: number;
    users: number;
    orders: number;
    conversions: number;
  };
}

// Define interface for channel object
interface Channel {
  label: string;
  value: string;
}

interface Props extends GlobalProps {}

interface State {
  preventRefresh: boolean;
  datesModal: boolean;
  channels: Channel[];
  start: Date | null;
  end: Date | null;
  period: string;
  type: string;
  view: string;
  usaReport: ReportResponse[];
  worldReport: ReportResponse[];
  barChartData: any;
  isChartJSRegistered: boolean;
}

const styles = (theme: any): any => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
  },
});

let preventRefreshTimer: NodeJS.Timeout;
let autoRefreshTimer: NodeJS.Timeout;

class Dashboard extends Component<Props, State> {
  chartOptions: any;
  autoRefreshListener: any;

  constructor(props: Props) {
    super(props);

    const stub = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
        },
      ],
    };

    this.state = {
      preventRefresh: false,
      datesModal: false,
      start: null,
      end: null,
      channels: [], // Default channel
      period: 'today',
      type: 'revenue',
      view: 'world',
      usaReport: [],
      worldReport: [],
      barChartData: stub,
      isChartJSRegistered: false,
    };

    this.chartOptions = {
      plugins: {
        legend: { display: false },
      },
      scales: {
        y: {
          ticks: { beginAtZero: true },
        },
      },
      layout: {
        padding: {
          top: 15,
          bottom: 5,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      parsing: false,
      normalized: true,
    };
  }

  componentDidMount(): void {
    const { socket, logger } = this.props;

    // Register Chart.js components first
    ChartRegistration.register();
    // Set logger to handle Chart.js errors
    ChartRegistration.setLogger(logger);
    this.setState({ isChartJSRegistered: true });

    // Then load data
    this.autoRefresh()().catch(logger.error);
    this.autoRefreshListener = this.autoRefreshListener || this.autoRefresh(true);

    // Setup socket events
    socket.service('v1/objects/customer-orders').removeListener('created', this.autoRefreshListener);
    socket.service('v1/objects/customer-orders').removeListener('patched', this.autoRefreshListener);
    socket.service('v1/objects/customer-orders').on('created', this.autoRefreshListener);
    socket.service('v1/objects/customer-orders').on('patched', this.autoRefreshListener);
  }

  componentWillUnmount(): void {
    const { socket } = this.props;
    if (this.autoRefreshListener) {
      socket.service('v1/objects/customer-orders').removeListener('create', this.autoRefreshListener);
      socket.service('v1/objects/customer-orders').removeListener('patched', this.autoRefreshListener);
      if (autoRefreshTimer) clearTimeout(autoRefreshTimer);
      if (preventRefreshTimer) clearTimeout(preventRefreshTimer);
    }

    // Unregister Chart.js components
    ChartRegistration.unregister();
  }

  autoRefresh =
    (skipLoader = false, forceReload = false) =>
    async () => {
      const { view, preventRefresh } = this.state;
      const { isLoading, toggleSnack, logger, user } = this.props;

      if (!forceReload && (preventRefresh || !user.hasRule(['dashboard-preview']))) return;
      if (autoRefreshTimer) clearTimeout(autoRefreshTimer);
      if (preventRefreshTimer) clearTimeout(preventRefreshTimer);

      this.setState({ preventRefresh: true });
      preventRefreshTimer = setTimeout(() => this.setState({ preventRefresh: false }), 1000 * 30);
      autoRefreshTimer = setTimeout(() => this.autoRefresh(true)(), 1000 * 60 * 5);

      if (!skipLoader) await isLoading(true);

      try {
        if (view === 'usa') {
          await Promise.all([this.loadUsaData(), this.loadBarData()]);
        } else {
          await Promise.all([this.loadWorldData(), this.loadBarData()]);
        }
      } catch (err: any) {
        logger.error(err);
        toggleSnack(err.message);
      }

      if (!skipLoader) await isLoading(false);
    };

  selectChannels = (value: Channel[]) => {
    let channels = [...value];

    if (channels.length > 1 && channels.find(c => c.value === 'all')) {
      const idx = channels.findIndex(c => c.value === 'all');
      channels.splice(idx, 1);
    }

    this.setState({ channels }, () => {
      this.autoRefresh(false, true)();
    });
  };

  onChange = (state: string) => (e: any) => {
    if (state === 'channels') return this.selectChannels(e);
    const { value } = e.target || {};

    // @ts-ignore
    this.setState({ [state]: value }, async () => {
      if (state === 'period' && value === 'custom') {
        this.toggleDatesModal();
      } else if (state === 'period') {
        this.setState({ start: null, end: null });
      }

      if (['period', 'view', 'type'].includes(state) && value !== 'custom') {
        await this.autoRefresh(false, true)();
      }
    });
  };

  loadBarData = async () => {
    const { api, logger } = this.props;
    const { period, start, end, channels } = this.state;

    try {
      const response = await api.service('v1/reports/charts/top-selling-items').create({
        channels: channels.map(c => c.value),
        period,
        limit: 25,
        start,
        end,
      });

      // Check if the response is in expected format
      if (response && response.labels && response.datasets && Array.isArray(response.datasets)) {
        // Pass the data as-is to the component
        this.setState({ barChartData: response });
      } else {
        // Create empty structure if response doesn't match expected format
        this.setState({
          barChartData: {
            labels: [],
            datasets: [
              {
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1,
              },
            ],
          },
        });
      }
    } catch (err) {
      logger.error('Error loading bar chart data:', err);
      // Set empty data on error
      this.setState({
        barChartData: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              borderColor: [],
              borderWidth: 1,
            },
          ],
        },
      });
    }
  };

  loadUsaData = async () => {
    const { api } = this.props;
    const { period, start, end, channels, type } = this.state;

    const usaReport = await api.service('v1/reports/map/usa').create({
      channels: channels.map(c => c.value),
      period,
      type,
      start,
      end,
    });

    this.setState({ usaReport });
  };

  loadWorldData = async () => {
    const { api } = this.props;
    const { period, start, end, channels, type } = this.state;

    const worldReport = await api.service('v1/reports/map/world').create({
      channels: channels.map(c => c.value),
      period,
      type,
      start,
      end,
    });

    this.setState({ worldReport });
  };

  getTotalData = (isUsa?: boolean) => {
    const { usaReport, worldReport } = this.state;

    if (isUsa) {
      const totals = usaReport.find(r => r.isTotals);

      if (totals) {
        return totals;
      }

      return {
        currentPeriod: {
          revenue: 0,
          users: 0,
          orders: 0,
          conversions: 0,
        },
        previewsPeriod: {
          revenue: 0,
          users: 0,
          orders: 0,
          conversions: 0,
        },
      };
    }

    const totals = worldReport.find(r => r.isTotals);

    if (totals) {
      return totals;
    }

    return {
      currentPeriod: {
        revenue: 0,
        users: 0,
        orders: 0,
        conversions: 0,
      },
      previewsPeriod: {
        revenue: 0,
        users: 0,
        orders: 0,
        conversions: 0,
      },
    };
  };

  normalizePeriod = (period: string) => {
    switch (period) {
      case 'today':
        return 'YESTERDAY';
      case 'yesterday':
        return 'DAY BEFORE';
      case 'last-hour':
        return 'ONE HOUR AGO';
      case 'last-24-hours':
        return '24 HOURS AGO';
      case 'last-7-days':
        return '7 DAYS AGO';
      case 'last-30-days':
        return '30 DAYS AGO';
      case 'previous-week':
      case '3-months':
      case '1-year':
      case 'custom':
        return 'PREVIOUS PERIOD';
      default:
        return 'PREVIOUS PERIOD';
    }
  };

  toggleDatesModal = () => {
    const { datesModal } = this.state;
    this.setState({ datesModal: !datesModal });
  };

  searchByCustomDate = async () => {
    await this.autoRefresh(false, true)();
    this.toggleDatesModal();
  };

  handleDateChange = (state: string) => (date: Date | null) => {
    this.setState({ [state]: date } as any);
  };

  render() {
    const { redux } = this.props;
    const {
      view,
      period,
      type,
      usaReport,
      worldReport,
      barChartData,
      datesModal,
      start,
      end,
      channels,
      isChartJSRegistered,
    } = this.state;

    const isUsa = view === 'usa';
    const totals = this.getTotalData(isUsa);
    const normalizedPeriod = this.normalizePeriod(period);
    const currency = redux?.state?.user?.tenant?.configs?.general?.defaultCurrency;

    if (!isChartJSRegistered) return <Box sx={{ p: 1 }}>Loading dashboard...</Box>;

    // Filter out totals for region data
    const regionData = (isUsa ? usaReport : worldReport).filter(r => !r.isTotals);

    return (
      <Box>
        {/* Header */}
        <DashboardHeader
          period={period}
          view={view}
          type={type}
          channels={channels}
          datesModal={datesModal}
          start={start}
          end={end}
          onChange={this.onChange}
          toggleDatesModal={this.toggleDatesModal}
          searchByCustomDate={this.searchByCustomDate}
          handleDateChange={this.handleDateChange}
          selectChannels={this.selectChannels}
        />

        {/* Top Stats in Grid */}
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="REVENUE"
              currentValue={totals.currentPeriod.revenue}
              previousValue={totals.previewsPeriod.revenue}
              comparisonLabel={normalizedPeriod}
              isCurrency
              currency={currency}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="NEW ORDERS"
              currentValue={totals.currentPeriod.orders}
              previousValue={totals.previewsPeriod.orders}
              comparisonLabel={normalizedPeriod}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="NEW CUSTOMERS"
              currentValue={totals.currentPeriod.users}
              previousValue={totals.previewsPeriod.users}
              comparisonLabel={normalizedPeriod}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="CONVERSIONS"
              currentValue={totals.currentPeriod.conversions}
              previousValue={totals.previewsPeriod.conversions}
              comparisonLabel={normalizedPeriod}
            />
          </Grid>
        </Grid>

        {/* Main content */}
        <Grid container spacing={1}>
          {/* Map Visualization */}
          <Grid item xs={12} md={7}>
            <MapVisualization
              reportData={isUsa ? usaReport : worldReport}
              channels={channels}
              period={period}
              type={type}
              view={view}
              start={start}
              end={end}
              onChange={this.onChange}
            />
          </Grid>

          {/* Top Selling Items Chart */}
          <Grid item xs={12} md={5}>
            <TopSellingItems barChartData={barChartData} chartOptions={this.chartOptions} />
          </Grid>

          {/* Region Stats Table - New Section */}
          <Grid item xs={12}>
            <RegionStatsTable data={regionData} view={view} type={type} period={normalizedPeriod} currency={currency} />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default connect({
  styles,
})(Dashboard);

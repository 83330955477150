import {
  Box,
  Card,
  CardHeader,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';

// Explicitly define the metric type for better type safety
type MetricType = 'revenue' | 'users' | 'orders' | 'conversions';

interface RegionData {
  state: string;
  country: string;
  currentPeriod: {
    revenue: number;
    users: number;
    orders: number;
    conversions: number;
  };
  previewsPeriod: {
    revenue: number;
    users: number;
    orders: number;
    conversions: number;
  };
}

interface RegionStatsTableProps {
  data: RegionData[];
  view: string;
  type: MetricType;
  period: string;
  currency?: string;
}

const RegionStatsTable: React.FC<RegionStatsTableProps> = ({ data, view, type, period, currency = 'USD' }) => {
  const theme = useTheme();

  // Sort data by current period value (descending)
  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => b.currentPeriod[type] - a.currentPeriod[type]).slice(0, 8); // Take top 8 regions for a more compact display
  }, [data, type]);

  // Calculate max value for progress bar scaling
  const maxValue = useMemo(() => {
    if (sortedData.length === 0) return 0;
    return sortedData[0].currentPeriod[type];
  }, [sortedData, type]);

  // Format values according to type
  const formatValue = (value: number) => {
    if (type === 'revenue') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    }
    return new Intl.NumberFormat('en-US').format(value);
  };

  // Calculate percentage change
  const getChange = (current: number, previous: number): [number, boolean] => {
    if (previous === 0) return [current > 0 ? 100 : 0, current > 0];
    const change = ((current - previous) / previous) * 100;
    return [Math.abs(Math.round(change)), change >= 0];
  };

  // Get appropriate title
  const getTitle = () => {
    const regionLabel = view === 'usa' ? 'States' : 'Countries';
    const metricLabel = type.charAt(0).toUpperCase() + type.slice(1);
    return `Top ${regionLabel} by ${metricLabel}`;
  };

  return (
    <Card>
      <CardHeader title={getTitle()} titleTypographyProps={{ variant: 'subtitle1', fontWeight: 'medium' }} />
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="5%">#</TableCell>
              <TableCell width="30%">Region</TableCell>
              <TableCell width="45%">Current</TableCell>
              <TableCell width="20%" align="right">
                vs Prev
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center" sx={{ py: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              sortedData.map((region, index) => {
                const currentValue = region.currentPeriod[type];
                const previousValue = region.previewsPeriod[type];
                const [changeValue, isPositive] = getChange(currentValue, previousValue);
                const regionName = view === 'usa' ? region.state : region.country;

                return (
                  <TableRow key={regionName} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Tooltip title={regionName} arrow placement="top">
                        <Typography variant="body2" noWrap>
                          {regionName}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={(currentValue / maxValue) * 100}
                            sx={{
                              height: 8,
                              borderRadius: 1,
                              bgcolor: theme.palette.grey[100],
                              '& .MuiLinearProgress-bar': {
                                borderRadius: 1,
                                bgcolor: index < 3 ? theme.palette.primary.main : theme.palette.grey[400],
                              },
                            }}
                          />
                        </Box>
                        <Box sx={{ minWidth: 50, textAlign: 'right' }}>
                          <Typography variant="caption" fontWeight="medium">
                            {formatValue(currentValue)}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="caption"
                        sx={{
                          px: 0.75,
                          py: 0.25,
                          borderRadius: 1,
                          display: 'inline-block',
                          color: isPositive ? theme.palette.success.dark : theme.palette.error.dark,
                          bgcolor: isPositive ? theme.palette.success.light : theme.palette.error.light,
                        }}
                      >
                        {isPositive ? '+' : '-'}
                        {changeValue}%
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default RegionStatsTable;

import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

import Map from './shared/Map';

interface MapVisualizationProps {
  reportData: any[];
  channels: any[];
  period: string;
  type: string;
  view: string;
  start: any;
  end: any;
  onChange: (state: string) => (e: any) => void;
  salesCoordinates?: any[];
}

const MapVisualization: React.FC<MapVisualizationProps> = ({
  reportData,
  channels,
  period,
  type,
  view,
  start,
  end,
  onChange,
  salesCoordinates = [],
}) => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent
        sx={{
          p: 1.25,
          '&:last-child': { pb: 1.25 },
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {view === 'usa' ? 'USA SALES MAP' : 'GLOBAL SALES MAP'}
        </Typography>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            flexGrow: 1,
            minHeight: '450px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Map
            reportData={reportData}
            salesCoordinates={salesCoordinates}
            channels={channels}
            period={period}
            type={type}
            view={view}
            start={start}
            end={end}
            onChange={onChange}
            hideControls={true}
            fullWidth={true}
            fullHeight={true}
            disableDropdowns={true}
            disableFilters={true}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MapVisualization;

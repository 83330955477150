import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';

/**
 * Utility class for handling Chart.js registration and unregistration
 * to avoid multiple registrations of the same components
 */
class ChartRegistration {
  private static isRegistered = false;
  private static logger: any = console;

  /**
   * Set a logger instance for the chart registration
   * @param logger The logger instance to use
   */
  static setLogger(logger: any): void {
    if (logger && typeof logger.error === 'function') {
      this.logger = logger;
      console.log('ChartRegistration: Logger set successfully');
    }
  }

  /**
   * Register Chart.js components
   */
  static register(): boolean {
    try {
      if (!this.isRegistered) {
        console.log('ChartRegistration: Registering Chart.js components');

        // Register required Chart.js components
        ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

        // Some additional Chart.js global configuration
        ChartJS.defaults.font.family = '"Roboto", "Helvetica", "Arial", sans-serif';
        ChartJS.defaults.color = 'rgba(0, 0, 0, 0.7)';
        ChartJS.defaults.layout.padding = 10;

        this.isRegistered = true;
        console.log('ChartRegistration: Successfully registered Chart.js components');
        return true;
      }
      return this.isRegistered;
    } catch (error) {
      console.error('ChartRegistration: Failed to register Chart.js components', error);
      this.isRegistered = false;
      return false;
    }
  }

  /**
   * Unregister Chart.js components
   */
  static unregister(): void {
    try {
      if (this.isRegistered) {
        console.log('ChartRegistration: Unregistering Chart.js components');
        ChartJS.unregister(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
        this.isRegistered = false;
      }
    } catch (error) {
      console.error('ChartRegistration: Failed to unregister Chart.js components', error);
    }
  }

  /**
   * Check if Chart.js is properly registered
   */
  static isReady(): boolean {
    return this.isRegistered;
  }
}

export default ChartRegistration;
